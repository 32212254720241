import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


import { Button } from '../../frameworkUI/components';
import { Header, Content } from '../../components/ShellFrame';

import { titles } from '../../data/position-titles.json';
import './Main.scss';

const MainBackgroundTitle = (props) => {
  const { children, bold } = props;
  return (
    <div className={`main-bg-column-item ${bold ? 'bold' : ''}`}>
      {children}
    </div>
  );
};

const MainBackground = () => {
  const [titleElements, setTitleElements] = useState(
    titles.map((title) => (
      <MainBackgroundTitle key={title}>{title}</MainBackgroundTitle>
    ))
  );

  const randomNumber = (maxInt) => {
    return Math.floor(Math.random() * maxInt);
  };

  const boldTitle = () => {
    const index = randomNumber(49);
    setTitleElements([
      ...titleElements.slice(0, index),
      <MainBackgroundTitle bold={true} key={titles[index]}>
        {titles[index]}
      </MainBackgroundTitle>,
      ...titleElements.slice(index + 1, titleElements.length),
    ]);
    setTimeout(() => {
      unboldTitle(index);
    }, 4000);
  };

  const unboldTitle = (index) => {
    setTitleElements([
      ...titleElements.slice(0, index),
      <MainBackgroundTitle bold={false} key={titles[index]}>
        {titles[index]}
      </MainBackgroundTitle>,
      ...titleElements.slice(index + 1, titleElements.length),
    ]);
  };

  useEffect(() => {
    boldTitle();
    let boldInterval = setInterval(boldTitle, 8000);

    return function cleanup() {
      clearInterval(boldInterval);
    };
  }, []);

  return (
    <div
      style={{
        margin: 'auto',
        color: '#D4D6D8',
        // color: '#B8BABD',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div style={{ flex: 'none', width: '450px', textAlign: 'center' }}>
        {titleElements.slice(0, 16)}
      </div>
      <div style={{ flex: 'none', width: '450px', textAlign: 'center' }}>
        {titleElements.slice(16, 32)}
      </div>
      <div style={{ flex: 'none', width: '450px', textAlign: 'center' }}>
        {titleElements.slice(32, 48)}
      </div>
    </div>
  );
};

const Main = () => {
  return (
    <>
      <Header sticky={true} />
      <Content scroll={true}>
        <div id="main-intro-container">
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              zIndex: 1,
              overflow: 'hidden',
              marginTop: '1rem',
              height: '50rem',
            }}
          >
            <MainBackground />
          </div>
          <div id="intro" style={{ height: '86vh' }}>
            <div style={{ height: '100%', paddingTop: '12%' }}>
              <div
                style={{
                  padding: '1rem 1rem 1.5rem 1rem',
                  display: 'table',
                  margin: 'auto',
                  backgroundColor: 'rgba(255,255,255,.8)',
                  boxShadow: '0 0 10px 6px rgba(255,255,255,.8)',
                  textAlign: 'center',
                }}
              >
                <div
                  style={{
                    fontSize: '42px',
                    fontWeight: 600,
                    width: '100%',
                  }}
                >
                  {/* Job Journey */}
                  Tidal Board
                </div>
                <p style={{ fontSize: '18px', fontWeight: 400, color: '#555' }}>
                  Organize and manage all your job applications in one place
                </p>
                <div style={{ paddingTop: '2rem' }}>
                  <Link to="/demo-board">
                    <Button size="lg" type="primary">
                      Try our demo board
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div
            id="info"
            style={{
              height: '100vh',
              position: 'relative',
              backgroundImage: 'linear-gradient(45deg, #0466C8, #C2699E)',
              color: 'white',
            }}
          >
            <div className="custom-shape-divider-top-1633139961">
              <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1200 120"
                preserveAspectRatio="none"
              >
                <path
                  d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                  className="shape-fill"
                ></path>
              </svg>
            </div>

            <div
              className="main-info-heading"
              style={{
                paddingTop: '16rem',
              }}
            >
              Hunting &nbsp;&#8594;&nbsp; Applied &nbsp;&#8594;&nbsp; Accepted
            </div>
            <div className="main-info-paragraph">
              Job Journey is the perfect tool for helping you through the job
              hunt. We provide you with the ability to track a job application
              from when you first discover it, to receiving that offer letter,
              and everything in between.
            </div>

            <div className="main-info-heading">Application Help</div>
            <div className="main-info-paragraph">
              We provide you with the tools to help you at each point during
              this process like our resume builder, email / communication
              guides, and interview prep.
            </div>

            <div className="main-info-heading">
              When can I start using Job Journey??
            </div>
            <div className="main-info-paragraph">
              Currently, our developers are hard at work designing the tools
              mentioned above. Our goal is to release a beta version of the tool
              in October. Then we plan to have everything ready to go by the
              start of the college job hunting season.
            </div>
          </div>
        </div>
      </Content>
    </>
  );
};

export default Main;
