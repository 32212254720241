import { JOB_PANEL_CLOSE, JOB_PANEL_OPEN } from "./actionTypes";


export const openJobPanel = (job) => {
  console.info('Opening Job Panel');
  return { type: JOB_PANEL_OPEN, payload: job };
};

export const closeJobPanel = () => {
  console.info('Closing Job Panel');
  return { type: JOB_PANEL_CLOSE, payload: undefined };
};
