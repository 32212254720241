import { useState } from 'react';

import { TextInput } from '../../frameworkUI/components';
import Modal, { ModalSpacing, ModalButtonBar } from './Modal';

const ColumnModal = (props) => {
  const { onCancelClose, onSubmit, add, defaultValue = '' } = props;
  const [colName, setColName] = useState(defaultValue);

  return (
    <Modal size={'sm'} onCancelClose={onCancelClose}>
      <div
        style={{
          fontWeight: '600',
          fontSize: '18px',
          color: 'rgba(0,0,0,.86)',
        }}
      >
        {add ? 'New Column' : 'Edit Column Name'}
      </div>

      <TextInput
        large
        clear
        label="Name"
        style={{ marginTop: '1rem', width: '100%' }}
        valueChange={(value) => setColName(value)}
        defaultValue={defaultValue}
      />
      <ModalSpacing />

      <ModalButtonBar
        secondary={{ text: 'Cancel', action: onCancelClose }}
        primary={{
          text: add ? 'Add' : 'Save',
          action: () => onSubmit(colName),
        }}
      />
    </Modal>
  );
};

export default ColumnModal;
