import axios from 'axios';

import { NODE_URL } from '../utils/env.utils';

export const getJob = (boardId, jobId) =>
  axios.get(`${NODE_URL}/board/job/${jobId}`);

export const postJob = (boardId, job) =>
  axios.post(`${NODE_URL}/board/job`, {
    boardId,
    job,
  });

export const putJob = (boardId, job) =>
  axios.put(`${NODE_URL}/board/job`, {
    boardId,
    job,
  });

export const putMoveJob = (boardId, source, destination) =>
  axios.put(`${NODE_URL}/board/job/move`, {
    headers: {},
    data: {
      boardId,
      source,
      destination,
    },
  });

export const deleteJob = (boardId, columnId, jobId) =>
  axios.delete(
    `${NODE_URL}/board/job/${jobId}/board/${boardId}/column/${columnId}`
  );

export const putMoveColumn = (boardId, columnId, source, destination) =>
  axios.put(`${NODE_URL}/board/column/move`, {
    headers: {},
    data: {
      boardId,
      columnId,
      source,
      destination,
    },
  });

export const putEditColumnName = (boardId, columnId, name) =>
  axios.put(`${NODE_URL}/board/column/name`, {
    headers: {},
    data: {
      boardId,
      columnId,
      name,
    },
  });

export const deleteColumn = (boardId, columnId) =>
  axios.delete(`${NODE_URL}/board/column`, {
    headers: {},
    data: {
      boardId,
      columnId,
    },
  });
