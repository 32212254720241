import { useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import { openCloumnModal } from '../../../redux/actions/modalActions';
import {
  fetchBoard,
  removeColumn,
  moveColumn,
  moveJob,
  editColumnName,
} from '../../../redux/actions/boardActions';
import { getKanbanState } from '../../../redux/kanbanSelectors';
import {
  Column,
  JobPanel,
  ModalDeployer,
  OptionsBar,
} from '../../../components/kanban';

import './KanbanBoard.scss';
import { useLocation } from 'react-router-dom';
import {
  fetchDemoBoard,
  removeDemoColumn,
  moveDemoColumn,
  moveDemoJob,
  editDemoColumnName,
} from '../../../redux/actions/demoBoardActions';

const KanbanBoard = () => {
  const location = useLocation();

  // Set action functions based on demo or non-demo
  let fetchBoardAct =
    location.pathname === '/demo-board' ? fetchDemoBoard : fetchBoard;
  let removeColumnAct =
    location.pathname === '/demo-board' ? removeDemoColumn : removeColumn;
  let moveColumnAct =
    location.pathname === '/demo-board' ? moveDemoColumn : moveColumn;
  let moveJobAct = location.pathname === '/demo-board' ? moveDemoJob : moveJob;
  let editColumnNameAct =
    location.pathname === '/demo-board' ? editDemoColumnName : editColumnName;

  const boardData = useSelector(getKanbanState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBoardAct());
  }, [dispatch]);

  const columns = !isEmpty(boardData)
    ? boardData.columns.map((col, index) => (
        <Column
          key={col._id}
          id={col._id}
          name={col.name}
          jobs={col.jobs}
          index={index}
          selfDestruct={(columnId) =>
            dispatch(removeColumnAct(boardData._id, columnId))
          }
          editColumn={(columnId, name) =>
            dispatch(editColumnNameAct(boardData._id, columnId, name))
          }
        />
      ))
    : [];

  const onJobDragEnd = (result) => {
    const { destination, source, type } = result;
    // Checks if dropped outside of area or if it never moved
    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }

    if (type === 'column') {
      dispatch(moveColumnAct(boardData._id, result));
    } else {
      dispatch(moveJobAct(boardData._id, result));
    }
  };

  return (
    <DragDropContext onDragEnd={onJobDragEnd}>
      <div id="kanban-container">
        <ModalDeployer boardId={boardData._id} />
        <OptionsBar />
        <div className="board-content">
          <JobPanel boardId={boardData._id} />
          <Droppable
            droppableId="board-columns"
            direction="horizontal"
            type="column"
          >
            {(provided) => (
              <div
                style={{
                  // height: '100%',
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  overflow: 'scroll',
                  paddingLeft: '1rem',
                  position: 'relative',
                }}
                {...provided.droppableProps}
                ref={provided.innerRef}
                id="idk"
              >
                {columns}
                {provided.placeholder}
                <div className="column-container">
                  <div
                    className="add-column-tile"
                    onClick={() => dispatch(openCloumnModal())}
                  >
                    + Add Column
                  </div>
                </div>
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </DragDropContext>
  );
};

export default KanbanBoard;
