const Content = (props) => {
  const { scroll } = props;
  return (
    <>
      {scroll ? (
        <div style={{ marginTop: '50px', height: '100%' }}>
          {props.children}
        </div>
      ) : (
        <div style={{ flexGrow: '1' }}>{props.children}</div>
      )}
    </>
  );
};

export default Content;
