import React from 'react';
import './TextArea.scss';

const TextArea = React.forwardRef((props, ref) => {
  const { valueChange, defaultValue, onFocus, onBlur, label } = props;

  return (
    <>
      <label style={{ width: '100%', ...props.style }}>
        <span className="label-text">{label}</span>
        <textarea
          className={`text-area`}
          onChange={(event) => valueChange(event.target.value)}
          defaultValue={defaultValue ? defaultValue : ''}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={ref}
        />
      </label>
    </>
  );
});

export default TextArea;
