import {
  ADD_JOB,
  MOVE_COLUMN,
  MOVE_JOB,
  REMOVE_JOB,
  FETCH_BOARD_SUCCESS,
  FETCH_BOARD_FAILED,
  UPDATE_JOB,
} from './actionTypes';
import axios from 'axios';
import { NODE_URL } from '../../utils/env.utils';
import {
  deleteColumn,
  deleteJob,
  postJob,
  putMoveJob,
  putEditColumnName,
  putJob,
  putMoveColumn,
} from '../../services/job.service';
import { DemoData } from '../../demo/data';

export const fetchBoard = (demo = false) => (dispatch) => {
  if (demo) {
    dispatch({ type: FETCH_BOARD_SUCCESS, payload: {data: DemoData} });
  } else {
    console.info('Sending request for board data');
    axios.get(`${NODE_URL}/board`).then(
      (response) => dispatch({ type: FETCH_BOARD_SUCCESS, payload: response }),
      (error) => dispatch({ type: FETCH_BOARD_FAILED, error })
    );
  }
};

export const addColumn = (boardId, name) => (dispatch) => {
  console.info('Sending request to add column');
  axios
    .post(`${NODE_URL}/board/column`, null, {
      params: {
        boardId,
        name,
      },
    })
    .then(
      (response) => dispatch({ type: FETCH_BOARD_SUCCESS, payload: response }),
      (error) => dispatch({ type: FETCH_BOARD_FAILED, error })
    );
};

export const removeColumn = (boardId, columnId) => (dispatch) => {
  console.info('Sending request to remove column');
  deleteColumn(boardId, columnId)
    .then((response) =>
      dispatch({ type: FETCH_BOARD_SUCCESS, payload: response })
    )
    .catch((error) => dispatch({ type: FETCH_BOARD_FAILED, error }));
};

export const editColumnName = (boardId, columnId, name) => (dispatch) => {
  console.info('Sending request to edit column name');
  putEditColumnName(boardId, columnId, name)
    .then((response) =>
      dispatch({ type: FETCH_BOARD_SUCCESS, payload: response })
    )
    .catch((error) => dispatch({ type: FETCH_BOARD_FAILED, error }));
};

export const moveColumn = (boardId, result) => (dispatch) => {
  const { source, destination, draggableId } = result;
  console.info('Sending request to move column');

  // TODO: Fix redux glitch
  putMoveColumn(boardId, draggableId, source.index, destination.index)
    .then((response) =>
      dispatch({ type: FETCH_BOARD_SUCCESS, payload: response })
    )
    .catch((error) => dispatch({ type: FETCH_BOARD_FAILED, error }));

  /**
   * Not sure if this is the best way to do this
   *
   * The following dispatch is to update the state of the column order
   * while waiting for the actual results from the server update response.
   * Else, there is a glitchy moment where the order is reset until the
   * server update response is successful.
   */
  dispatch({
    type: MOVE_COLUMN,
    payload: {
      source: source.index,
      destination: destination.index,
    },
  });
};

// Jobs

export const addJob = (boardId, columnId, job) => (dispatch) => {
  console.info('Sending post new job', boardId, 'job', job);
  job.column = columnId;
  postJob(boardId, job)
    .then((response) =>
      dispatch({ type: FETCH_BOARD_SUCCESS, payload: response })
    )
    .catch((error) => dispatch({ type: FETCH_BOARD_FAILED, error }));

  return {
    type: ADD_JOB,
    payload: job,
  };
};

export const updateJob = (boardId, job) => (dispatch) => {
  console.info(`Sending put update job ${boardId} : job ${job}`, job);
  putJob(boardId, job)
    .then((response) =>
      dispatch({ type: FETCH_BOARD_SUCCESS, payload: response })
    )
    .catch((error) => dispatch({ type: FETCH_BOARD_FAILED, error }));

  return {
    type: UPDATE_JOB,
    payload: job,
  };
};

export const removeJob = (boardId, columnId, jobId) => (dispatch) => {
  console.info('Removing job');
  deleteJob(boardId, columnId, jobId)
    .then((response) =>
      dispatch({ type: FETCH_BOARD_SUCCESS, payload: response })
    )
    .catch((error) => dispatch({ type: FETCH_BOARD_FAILED, error }));

  return {
    type: REMOVE_JOB,
    payload: {},
  };
};

export const moveJob = (boardId, result) => (dispatch) => {
  console.info('Moving Job');
  const { destination, source } = result;

  putMoveJob(
    boardId,
    { index: source.index, columnId: source.droppableId },
    { index: destination.index, columnId: destination.droppableId }
  )
    .then((response) =>
      dispatch({ type: FETCH_BOARD_SUCCESS, payload: response })
    )
    .catch((error) => dispatch({ type: FETCH_BOARD_FAILED, error }));

  dispatch({
    type: MOVE_JOB,
    payload: {
      destination,
      source,
    },
  });
};
