import axios from 'axios';

import { NODE_URL } from '../utils/env.utils';
import { v4 as uuidv4 } from 'uuid';
import { companies } from '../data/company-data-demo.json';

// export const getCompanyList = () => {
//   const list = axios.get(`${NODE_URL}/company/names`);
//   console.log(':LLIS', list);
//   return list;
// };

// Temp for no backend
export const getCompanyList = async () => ({ data: companies });
