import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { SideMenu, SideMenuItem } from '../../frameworkUI/components';
import Contacts from './Contacts/Contacts';
import KanbanBoard from './KanbanBoard/KanbanBoard';

import { Admin as AdminIcon, Kanban, Contact, Logo } from '../../frameworkUI/icons';

import './Dashboard.scss';
import { Admin } from '..';

const Dashboard = () => {
  let { path } = useRouteMatch();

  return (
    <div id="dashboard-view">
      <SideMenu>
        <SideMenuItem to="/" Icon={Logo} heading>
          Tidal Board
        </SideMenuItem>
        <SideMenuItem to="/demo-board" Icon={Kanban}>
          Dashboard
        </SideMenuItem>
        <SideMenuItem to="/demo-board/contacts" Icon={Contact}>
          Contacts
        </SideMenuItem>
        {/* <SideMenuItem to="/demo-board/admin" Icon={AdminIcon}>
          Admin
        </SideMenuItem> */}
      </SideMenu>
      <div id="dashboard-content">
        <Switch>
          <Route exact path={path}>
            <KanbanBoard />
          </Route>
          <Route path={`${path}/contacts`}>
            <Contacts />
          </Route>
          <Route path={`${path}/admin`}>
            <Admin />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default Dashboard;
