import { useDispatch, useSelector } from 'react-redux';

import { getModalState } from '../../redux/modalSelectors';
import { MODAL_STATES } from '../../redux/reducers/modalReducer';
import { addColumn, addJob } from '../../redux/actions/boardActions';
import {
  closeJobModal,
  closeColumnModal,
} from '../../redux/actions/modalActions';
import ColumnModal from '../Modal/ColumnModal';
import CreateJobModal from '../Modal/CreateJobModal';
import { useLocation } from 'react-router-dom';
import { addDemoColumn, addDemoJob } from '../../redux/actions/demoBoardActions';

const ModalDeployer = (props) => {
  const { boardId } = props;

  const modalData = useSelector(getModalState);
  const dispatch = useDispatch();
  const location = useLocation();

    // Set action functions based on demo or non-demo
    let addColumnAct =
    location.pathname === '/demo-board' ? addDemoColumn : addColumn;
  let addJobAct =
    location.pathname === '/demo-board' ? addDemoJob : addJob;

  switch (modalData.type) {
    case MODAL_STATES.JOB_MODAL: {
      return (
        <CreateJobModal
          add
          onCancelClose={() => dispatch(closeJobModal())}
          onSubmit={(job) => {
            dispatch(addJobAct(boardId, modalData.id, job));
            dispatch(closeJobModal());
          }}
        />
      );
    }
    case MODAL_STATES.COLUMN_MODAL: {
      return (
        <ColumnModal
          add
          onCancelClose={() => dispatch(closeColumnModal())}
          onSubmit={(name) => {
            dispatch(addColumnAct(boardId, name));
            dispatch(closeColumnModal());
          }}
        />
      );
    }
    default:
      return null;
  }
};

export default ModalDeployer;
