import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { Button } from '../../frameworkUI/components';

import './Admin.scss';
import CompanyManagement from './CompanyManagement';
import { Link } from 'react-router-dom';
import LogoDisplay from './LogoDisplay';

const Admin = () => {
  let { path } = useRouteMatch();

  return (
    <div>
      <div>
        <div className="tab-btn-main">
          <Link to={`${path}/`} className="tab-btn">
            <Button size="sm">Admin Home</Button>
          </Link>
          <Link to={`${path}/companies`} className="tab-btn">
            <Button size="sm">Company</Button>
          </Link>
          <Link to={`${path}/icon-library`} className="tab-btn">
            <Button size="sm">Icon Library</Button>
          </Link>
        </div>
        <div id="dashboard-content">
          <Switch>
            <Route exact path={path}>
                <div
                  style={{
                    display: 'flex',
                    height: '80%',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{ fontSize: '1.9rem', color: 'rgb(100,100,100)' }}
                  >
                    Admin Home Page - Coming soon
                  </div>
                </div>
            </Route>
            <Route path={`${path}/companies`}>
              <CompanyManagement />
            </Route>
            <Route path={`${path}/icon-library`}>
              <LogoDisplay />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Admin;
