import { useEffect, useState } from 'react';

import { TextInput } from '../../frameworkUI/components';
import Modal, { ModalSpacing, ModalButtonBar, ModalHeading } from './Modal';
import { Autocomplete } from '../../frameworkUI/components';

import styles from './CreateJobModal.module.scss';
import { getCompanyList } from '../../services/utility.service';

const CreateJobModal = (props) => {
  const { onCancelClose, onSubmit } = props;
  const [company, setCompany] = useState({ _id: null, value: '' });
  const [position, setPosition] = useState('');
  const [companyList, setCompanyList] = useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(true);

  useEffect(() => {
    getCompanyList()
      .then((response) => {
        const formattedList = response.data.map((company) => ({
          _id: company._id,
          value: company.name,
          // TODO: Remove later
          color: company.color
        }));
        setCompanyList(formattedList);
      })
      .catch((error) => console.error('Error fetching list of companies'));
  }, []);

  return (
    <Modal size={'md'} onCancelClose={onCancelClose}>
      <ModalHeading>New Job</ModalHeading>
      <div className={styles.company_form}>
        <div className={styles.company_row}>
          <Autocomplete
            large
            fullList={companyList}
            label="Company Name"
            onChange={(val) => setCompany({ ...company, ...val })}
          />
        </div>
        <TextInput
          large
          label="Postion Title"
          style={{ width: '100%' }}
          valueChange={setPosition}
        />
      </div>
      <ModalSpacing />
      <ModalButtonBar
        secondary={{ text: 'Cancel', action: onCancelClose }}
        primary={{
          text: 'Add',
          action: () => onSubmit({ company, position }),
        }}
      />
    </Modal>
  );
};

export default CreateJobModal;
