export const MOVE_COLUMN = 'MOVE_COLUMN';
export const ADD_JOB = 'ADD_JOB';
export const UPDATE_JOB = 'UPDATE_JOB';
export const REMOVE_JOB = 'REMOVE_JOB';
export const MOVE_JOB = 'MOVE_JOB';
export const FETCH_BOARD_SUCCESS = 'FETCH_BOARD_SUCCESS';
export const FETCH_BOARD_FAILED = 'FETCH_BOARD_FAILED';

// Modals
export const JOB_MODAL_OPEN = 'JOB_MODAL_OPEN';
export const JOB_MODAL_CLOSE = 'JOB_MODAL_CLOSE';
export const COLUMN_MODAL_OPEN = 'COLUMN_MODAL_OPEN';
export const COLUMN_MODAL_CLOSE = 'COLUMN_MODAL_CLOSE';

// Job Panel
export const JOB_PANEL_OPEN = 'JOB_PANEL_OPEN';
export const JOB_PANEL_CLOSE = 'JOB_PANEL_CLOSE';
