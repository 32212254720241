import { v4 as uuidv4 } from 'uuid';
import store from '../redux/store';

export const addDemoColumnReduce = (name) => {
  const board = store.getState().kanbanBoard;
  board.columns.push({
    _id: uuidv4(),
    name,
    createdOn: new Date().toISOString(),
    jobs: [],
  });

  return board;
};

export const removeDemoColumnReduce = (columnId) => {
  const board = store.getState().kanbanBoard;
  const columnIndex = board.columns.findIndex((col) => col._id === columnId);
  board.columns.splice(columnIndex, 1);
  return board;
};

export const editDemoColumnNameReduce = (columnId, name) => {
  const board = store.getState().kanbanBoard;
  const columnIndex = board.columns.findIndex((col) => col._id === columnId);
  board.columns[columnIndex].name = name;

  return board;
};

export const moveDemoColumnReduce = (result) => {
  const { source, destination } = result;
  const board = store.getState().kanbanBoard;
  console.info('Moving demo column');

  // Return if source and dest are the same (add'l validation for BE)
  if (source === destination) {
    return board;
  }

  board.columns.splice(
    destination.index,
    0,
    ...board.columns.splice(source.index, 1)
  );
  return board;
};

// Jobs

export const addDemoJobReduce = (columnId, job) => {
  const board = store.getState().kanbanBoard;
  const columnIndex = !columnId
    ? 0
    : board.columns.findIndex((col) => col._id === columnId);
  const tempDate = new Date();
  board.columns[columnIndex].jobs.push({
    _id: uuidv4(),
    job: {
      _id: uuidv4(),
      company_id: job.company._id || uuidv4(),
      company: job.company.value,
      position: job.position,
      color: job.company.color || 'black',
      column: columnId,
      status:
        'Created on ' +
        (tempDate.getMonth() + 1) +
        '/' +
        tempDate.getDate() +
        '/' +
        (tempDate.getFullYear() - 2000),
      __v: 0,
    },
  });
  console.info('Sending post new job', job);
  return board;
};

export const updateDemoJobReduce = (job) => {
  const board = store.getState().kanbanBoard;

  board.columns.forEach((col) => {
    col.jobs.forEach((j) => {
      if (j.job._id === job._id) {
        j.job = job;
      }
    });
  });

  return board;
};

export const getDemoJobReduce = async (boardId, job) => {
  const board = store.getState().kanbanBoard;
  const allJobs = board.columns.map((col) => col.jobs).flat();
  const jobIndex = allJobs.findIndex((j) => j.job._id === job);

  return { data: allJobs[jobIndex].job };
};

export const removeDemoJobReduce = (columnId, jobId) => {
  const board = store.getState().kanbanBoard;
  const columnIndex = board.columns.findIndex((col) => col._id === columnId);
  const jobIndex = board.columns[columnIndex].jobs.findIndex(
    (j) => j.job._id === jobId
  );

  board.columns[columnIndex].jobs.splice(jobIndex, 1);

  return board;
};

export const moveDemoJobReduce = (source, destination) => {
  console.info('Moving Demo Reduce Job');
  const board = store.getState().kanbanBoard;

  // Return if source and dest are the same (add'l validation for BE)
  if (
    source.columnId === destination.columnId &&
    source.index === destination.index
  ) {
    return board;
  }

  let { columns } = board;
  const sourceColumnIndex = columns.findIndex(
    (col) => col._id === source.columnId
  );
  const destColumnIndex = columns.findIndex(
    (col) => col._id === destination.columnId
  );

  board.columns[destColumnIndex].jobs.splice(
    destination.index,
    0,
    ...board.columns[sourceColumnIndex].jobs.splice(source.index, 1)
  );

  return board;
};
