import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Autocomplete,
  Button,
  TextArea,
  TextInput,
} from '../../frameworkUI/components';
import { updateJob } from '../../redux/actions/boardActions';
import { updateDemoJob } from '../../redux/actions/demoBoardActions';

import styles from './styles/JobPanelForm.module.scss';

const JobPanelForm = (props) => {
  const { boardId, company, companyList, setCompanyData } = props;
  const dispatch = useDispatch();
  const location = useLocation();

  // Set action functions based on demo or non-demo
  let updateJobAct =
    location.pathname === '/demo-board' ? updateDemoJob : updateJob;

  return (
    <div style={{ height: '100%' }}>
      <div className={styles.input_row}>
        <Autocomplete
          defaultValue={company.company}
          fullList={companyList}
          label="Company Name"
          onChange={({ value }) =>
            setCompanyData((current) => ({ ...current, company: value }))
          }
        />
        <TextInput
          clear
          defaultValue={company.position}
          label="Postion"
          valueChange={(position) =>
            setCompanyData((current) => ({ ...current, position }))
          }
        />
      </div>
      <div className={styles.input_row}>
        <TextInput
          clear
          defaultValue={company.location}
          label="Location"
          valueChange={(location) =>
            setCompanyData((current) => ({ ...current, location }))
          }
        />
        <TextInput
          clear
          defaultValue={company.salary}
          pattern="[0-9]+([,][0-9]{1,2})?"
          type="currency"
          label="Salary / Wage"
          valueChange={(salary) =>
            setCompanyData((current) => ({ ...current, salary }))
          }
        />
      </div>
      <div className={styles.input_row}>
        <TextInput
          clear
          type="link"
          label="Posting Link"
          valueChange={(link) =>
            setCompanyData((current) => ({ ...current, link }))
          }
        />
      </div>
      <div className={styles.input_row}>
        <TextInput
          label="Date Posted"
          valueChange={(datePosted) =>
            setCompanyData((current) => ({ ...current, datePosted }))
          }
        />
        <TextInput
          label="Date Applied"
          valueChange={(dateApplied) =>
            setCompanyData((current) => ({ ...current, dateApplied }))
          }
        />
      </div>
      <div className={styles.input_row} style={{ height: '300px' }}>
        <TextArea
          label="Notes"
          style={{ height: '300px' }}
          valueChange={(dateApplied) =>
            setCompanyData((current) => ({ ...current, dateApplied }))
          }
        />
      </div>
      <div className={styles.input_row}>
        <Button size="md" type="primary" click={() => dispatch(updateJobAct(boardId, company))}>
          SAVE
        </Button>
      </div>
    </div>
  );
};

export default JobPanelForm;
