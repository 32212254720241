import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';

import { Button } from '../../frameworkUI/components';

import { getJobPanelState } from '../../redux/jobPanelSelectors';
import { closeJobPanel } from '../../redux/actions/jobPanelActions';
import { removeJob } from '../../redux/actions/boardActions';
import { getJob } from '../../services/job.service';
import JobPanelDetails from './JobPanelDetails';
import JobPanelForm from './JobPanelForm';

import styles from './styles/JobPanel.module.scss';
import { getDemoJobReduce } from '../../demo/demoReduce';
import { removeDemoJob } from '../../redux/actions/demoBoardActions';

const InitialJobState = {
  _id: '',
  company: '',
  position: '',
  salary: '',
  location: '',
  link: '',
};

const BackgroundFilter = () => {
  const jobData = useSelector(getJobPanelState);
  const dispatch = useDispatch();

  return (
    <div
      className={classnames(
        styles.bg_filter,
        jobData?.open && styles.bg_filter_open
      )}
      onClick={() => dispatch(closeJobPanel())}
    >
      {''}
    </div>
  );
};

const JobPanel = (props) => {
  const { boardId } = props;
  const jobData = useSelector(getJobPanelState);
  const dispatch = useDispatch();
  const location = useLocation();

  // Set action functions based on demo or non-demo
  let removeJobAct =
    location.pathname === '/demo-board' ? removeDemoJob : removeJob;
  
  const [company, setCompany] = useState(InitialJobState);
  const [loadingJob, setLoadingJob] = useState(true);
  const [companyList, setCompanyList] = useState([]);
  const [originColumn, setOriginColumn] = useState(undefined);

  useEffect(() => {
    if (jobData.id) {
      setLoadingJob(true);
      setCompany(InitialJobState);
      let getJobAct =
        location.pathname === '/demo-board' ? getDemoJobReduce : getJob;
      getJobAct(1, jobData.id)
        .then((res) => {
          setCompany({ ...InitialJobState, ...res.data });
          setOriginColumn(res.data.column);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => setLoadingJob(false));
    }
  }, [jobData]);

  const deleteJob = () => {
    dispatch(closeJobPanel());
    dispatch(removeJobAct(boardId, originColumn, company._id));
  };

  return (
    <>
      <BackgroundFilter />
      <div
        className={classnames(
          styles.content_body,
          jobData?.open && styles.open,
          styles.fixed_elements
        )}
      >
        {jobData?.open && !loadingJob && (
          <div className={styles.panel_body}>
            <div style={{ height: '100%' }}>
              <JobPanelDetails company={company} />

              <div>
                <Button size="sm" click={() => dispatch(closeJobPanel())}>
                  Close
                </Button>
                <Button size="sm" click={() => deleteJob()}>
                  Delete
                </Button>
              </div>
            </div>

            <div style={{ flexGrow: 1, margin: '0 2rem' }}>
              <JobPanelForm
                boardId={boardId}
                company={company}
                companyList={companyList}
                setCompanyData={setCompany}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default JobPanel;
