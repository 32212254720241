import { findIndex } from 'lodash';
import {
  MOVE_COLUMN,
  ADD_JOB,
  UPDATE_JOB,
  REMOVE_JOB,
  MOVE_JOB,
  FETCH_BOARD_SUCCESS,
  FETCH_BOARD_FAILED,
} from '../actions/actionTypes';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_BOARD_SUCCESS: {
      const { data } = action.payload;
      return {
        ...data,
      };
    }
    case FETCH_BOARD_FAILED: {
      return {
        // ...tempData,
        ...state,
      };
    }
    case MOVE_COLUMN: {
      const { destination, source } = action.payload;

      let modifiedColumns = [...state.columns];
      modifiedColumns.splice(
        destination,
        0,
        ...modifiedColumns.splice(source, 1)
      );

      return {
        ...state,
        columns: [...modifiedColumns],
      };
    }
    case ADD_JOB: {
      const { company, position } = action.payload;
      const columns = [...state.columns];
      const jobId = Math.floor(Math.random() * 10000) + '-job';
      columns[0].jobs.push({
        company: company.value,
        id: jobId,
        position: position,
        location: '',
        color: '#444444',
      });

      return {
        ...state,
        columns,
      };
    }
    case UPDATE_JOB: {
      const job = action.payload;
      const columns = [...state.columns];
      let targetCol = 0;

      columns.array.forEach((col, index) => {
        const targetJobIndex = findIndex(col.jobs, { _id: job._id });
        if (targetJobIndex > -1) {
          targetCol = index;
          return;
        }
      });

      const targetJobIndex = findIndex(columns[targetCol].jobs, {
        _id: job._id,
      });
      targetCol.jobs[targetJobIndex] = job;

      return {
        ...state,
        columns,
      };
    }
    case REMOVE_JOB: {
      return {
        ...state,
      };
    }
    case MOVE_JOB: {
      const { destination, source } = action.payload;
      const modifiedState = { ...state };
      const { columns } = modifiedState;

      const sourceColumn = columns.find(
        (col) => col._id === source.droppableId
      );
      const destinationColumn = columns.find(
        (col) => col._id === destination.droppableId
      );

      destinationColumn?.jobs.splice(
        destination.index,
        0,
        ...sourceColumn?.jobs.splice(source.index, 1)
      );

      return {
        ...modifiedState,
      };
    }
    default:
      return state;
  }
}
