import { Draggable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';

import { openJobPanel } from '../../redux/actions/jobPanelActions';
import { Briefcase, Location } from '../../frameworkUI/icons';

import styles from './styles/JobTile.module.scss';

const JobTile = (props) => {
  const { id, company, position, location, color, index, status } = props;
  const dispatch = useDispatch();

  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => {
        const containerStyle = {
          transition: 'filter 0.2s ease',
          filter: snapshot.isDragging ? 'brightness(0.97)' : 'none',
          ...provided.draggableProps.style,
        };

        return (
          <div
            className={styles.job_tile_container}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            style={containerStyle}
            onClick={() => dispatch(openJobPanel({ id }))}
          >
            <div className={styles.job_tile_heading} style={{ color: color }}>
              {company}
            </div>
            <div className={styles.job_tile_body}>
              <div className={styles.job_tile_position}>
                <Briefcase className={styles.icon_xs} />
                {position}
              </div>
              <div className={styles.job_tile_location}>
                {location && (
                  <>
                    <Location className={styles.icon_xs} />
                    {location}
                  </>
                )}
                {/* <Location className={styles.icon_xs} /> */}
                {/* {location} */}
              </div>
              <div className={styles.job_tile_status}>{status}</div>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

export default JobTile;
