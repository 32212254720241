import './Button.scss';

const Button = (props) => {
  const { children, click, style, className, type, size } = props;

  let classList = className ? className : '';
  classList += ` button button-${size} button-${type}`;

  return (
    <button onClick={click} className={classList} style={style}>
      {children}
    </button>
  );
};

export default Button;
