import {
  JOB_MODAL_OPEN,
  JOB_MODAL_CLOSE,
  COLUMN_MODAL_OPEN,
  COLUMN_MODAL_CLOSE,
} from '../actions/actionTypes';

export const MODAL_STATES = {
  JOB_MODAL: 'JOB_MODAL',
  COLUMN_MODAL: 'COLUMN_MODAL',
};

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case JOB_MODAL_OPEN: {
      const { id } = action.payload;
      return {
        type: MODAL_STATES.JOB_MODAL,
        id,
      };
    }
    case JOB_MODAL_CLOSE: {
      return {
        type: undefined,
      };
    }
    case COLUMN_MODAL_OPEN: {
      // const { data } = action.payload;
      return {
        type: MODAL_STATES.COLUMN_MODAL,
      };
    }
    case COLUMN_MODAL_CLOSE: {
      return {
        type: undefined,
      };
    }
    default:
      return state;
  }
}
