import { FETCH_BOARD_SUCCESS } from './actionTypes';
import { DemoData } from '../../demo/data';
import {
  addDemoColumnReduce,
  removeDemoColumnReduce,
  editDemoColumnNameReduce,
  moveDemoColumnReduce,
  addDemoJobReduce,
  updateDemoJobReduce,
  removeDemoJobReduce,
  moveDemoJobReduce,
} from '../../demo/demoReduce';

export const fetchDemoBoard = () => (dispatch) => {
  dispatch({ type: FETCH_BOARD_SUCCESS, payload: { data: DemoData } });
};

export const addDemoColumn = (boardId, name) => (dispatch) => {
  console.info('Adding demo column');
  dispatch({
    type: FETCH_BOARD_SUCCESS,
    payload: { data: addDemoColumnReduce(name) },
  });
};

export const removeDemoColumn = (boardId, columnId) => (dispatch) => {
  console.info('Remove demo column');
  dispatch({
    type: FETCH_BOARD_SUCCESS,
    payload: { data: removeDemoColumnReduce(columnId) },
  });
};

export const editDemoColumnName = (boardId, columnId, name) => (dispatch) => {
  console.info('Edit demo column name');
  dispatch({
    type: FETCH_BOARD_SUCCESS,
    payload: { data: editDemoColumnNameReduce(columnId, name) },
  });
};

export const moveDemoColumn = (boardId, result) => (dispatch) => {
  console.info('Sending request to move column');

  dispatch({
    type: FETCH_BOARD_SUCCESS,
    payload: { data: moveDemoColumnReduce(result) },
  });
};

// Jobs

export const addDemoJob = (boardId, columnId, job) => (dispatch) => {
  console.info('Sending post new job', boardId, 'job', job);

  dispatch({
    type: FETCH_BOARD_SUCCESS,
    payload: { data: addDemoJobReduce(columnId, job) },
  });
};

export const updateDemoJob = (boardId, job) => (dispatch) => {
  console.info(`Updating demo job ${job}`, job);
  dispatch({
    type: FETCH_BOARD_SUCCESS,
    payload: { data: updateDemoJobReduce(job) },
  });
};

export const removeDemoJob = (boardId, columnId, jobId) => (dispatch) => {
  console.info('Removing demo job', columnId);
  dispatch({
    type: FETCH_BOARD_SUCCESS,
    payload: { data: removeDemoJobReduce(columnId, jobId) },
  });
};

export const moveDemoJob = (boardId, result) => (dispatch) => {
  console.info('Moving Demo Job');
  const { destination, source } = result;

  dispatch({
    type: FETCH_BOARD_SUCCESS,
    payload: {
      data: moveDemoJobReduce(
        { index: source.index, columnId: source.droppableId },
        { index: destination.index, columnId: destination.droppableId }
      ),
    },
  });
};
