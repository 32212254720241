import './Dropdown.scss';

export const DropdownContent = (props) => {
  return <div className="dropdown-content">{props.children}</div>;
};

export const OptionEvent = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      {props.children}
    </div>
  );
};

const Dropdown = (props) => {
  const { className, onMouseLeave } = props;
  return (
    <div className={`dropdown ${className}`} onMouseLeave={onMouseLeave}>
      {props.children}
    </div>
  );
};

export default Dropdown;
