export const DemoData = {
  _id: '601b6eec0c35855450e3ec5f',
  name: 'Demo Board',
  columns: [
    {
      _id: '6077a6d647eb6d0a18bf7f1d',
      name: 'Posted',
      createdOn: '2021-04-15T02:37:10.155Z',
      jobs: [
        {
          _id: '615e7784102d3fa2ffd0facd',
          job: {
            _id: '615e7784102d3fa2ffd0facc',
            company_id: '602f33cc15ea8c32cbd62ddc',
            company: 'IBM',
            position: 'Front End Developer',
            location: 'Durham, NC',
            color: '#006699',
            column: '6077a6d647eb6d0a18bf7f1d',
            status: 'Created on 10/08/22',
            __v: 0,
          },
        },
      ],
    },
    {
      _id: '6077a6c047eb6d0a18bf7f1a',
      name: 'Applied',
      createdOn: '2021-04-15T02:36:48.986Z',
      jobs: [
        {
          _id: '6077a77675b6c10a51db2dee',
          job: {
            _id: '6077a77675b6c10a51db2ded',
            company_id: '602ca6dca13b4c18649ce323',
            company: 'Bank of America',
            position: 'Software Engineer',
            location: 'Seattle, WA',
            color: '#d4001a',
            column: '6077a6c047eb6d0a18bf7f1a',
            status: 'Applied on 10/16/22',
            __v: 0,
          },
        },
        {
          _id: '6077a79675b6c10a51db2df0',
          job: {
            _id: '6077a79675b6c10a51db2def',
            company_id: '602f33cc15ea8c32cbd62ddc',
            company: 'Spotify',
            position: 'First Line Manager',
            location: 'Remote',
            color: '#1db954',
            column: '6077a6c047eb6d0a18bf7f1a',
            status: 'Applied on 10/15/22',
            __v: 0,
          },
        },
      ],
    },
    {
      _id: '6084e31fa7bdfa71a7bd7d67',
      name: 'Interviewing',
      createdOn: '2021-04-25T03:33:51.792Z',
      jobs: [],
    },
    {
      _id: '610edb60a5555971eeda0ab6',
      name: 'Accepted',
      createdOn: '2021-08-07T19:13:36.716Z',
      jobs: [],
    },
    {
      _id: '610edb66a5555971eeda0ab7',
      name: 'Rejected',
      createdOn: '2021-08-07T19:13:42.947Z',
      jobs: [],
    },
  ],
};
