import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import { openJobModal } from '../../redux/actions/modalActions';
import Dropdown, {
  DropdownContent,
  OptionEvent,
} from '../../frameworkUI/components/Dropdown/Dropdown';
import ColumnModal from '../Modal/ColumnModal';
import { JobTile } from './';
import GrabIcon from '../../frameworkUI/icons/GrabIcon.svg';
import OptionDotsIcon from '../../frameworkUI/icons/OptionDots.svg';

import styles from './styles/Column.module.scss';

const Column = (props) => {
  const { id, name, jobs, index, selfDestruct, editColumn } = props;
  const dispatch = useDispatch();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [colModalOpen, setColModalOpen] = useState(false);

  const jobComponents = jobs
    .filter((job) => job?.job?.company !== undefined)
    .map((job, index) => (
      <JobTile
        key={job.job._id}
        id={job.job._id}
        company={job.job.company}
        position={job.job.position}
        location={job.job.location}
        color={job.job.color}
        status={job.job.status}
        index={index}
      />
    ));

  return (
    <>
      {colModalOpen ? (
        <ColumnModal
          onCancelClose={() => setColModalOpen(false)}
          onSubmit={(name) => {
            setColModalOpen(false);
            editColumn(id, name);
          }}
          defaultValue={name}
        />
      ) : null}
      <Draggable draggableId={id} index={index}>
        {(provided) => (
          <div
            className={styles.column_container}
            {...provided.draggableProps}
            ref={provided.innerRef}
          >
            <div className={styles.column_header}>
              <img
                className={styles.column_drag_icon}
                {...provided.dragHandleProps}
                src={GrabIcon}
              />
              <div className={styles.column_header_text}>{name}</div>
              <Dropdown
                className={styles.dropdown_container}
                onMouseLeave={() => setDropdownOpen(false)}
              >
                <div
                  className={styles.column_options_wrapper}
                  onClick={() => setDropdownOpen(true)}
                >
                  <img
                    className={styles.column_options_icon}
                    src={OptionDotsIcon}
                  />
                </div>
                {dropdownOpen ? (
                  <DropdownContent>
                    <OptionEvent onClick={() => setColModalOpen(true)}>
                      Edit Name
                    </OptionEvent>
                    <OptionEvent>Clear List</OptionEvent>
                    <OptionEvent
                      className={styles.danger_text}
                      onClick={() => selfDestruct(id)}
                    >
                      Delete Column
                    </OptionEvent>
                  </DropdownContent>
                ) : null}
              </Dropdown>
            </div>
            <Droppable droppableId={id} type="job">
              {(provided, snapshot) => {
                const columnStyle = {
                  transition: 'background-color 0.2s ease',
                  backgroundColor: snapshot.isDraggingOver
                    ? 'rgba(238, 242, 244)'
                    : '#EBEFF1',
                };

                return (
                  <div
                    className={styles.column_body}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={columnStyle}
                  >
                    {jobComponents}
                    {provided.placeholder}
                    <div
                      className={styles.column_add_job_tile}
                      onClick={() => dispatch(openJobModal(id))}
                    >
                      + Add New Job
                    </div>
                  </div>
                );
              }}
            </Droppable>
          </div>
        )}
      </Draggable>
    </>
  );
};

export default Column;
