import { isUndefined } from 'lodash';
import { Link, useLocation } from 'react-router-dom';

import './SideMenuItem.scss';

const SideMenuItem = (props) => {
  const { Icon, to, children, className, onClick, heading } = props;
  let { pathname } = useLocation();

  if (isUndefined(to)) {
    return (
      <div className={`sidebar-link ${className}`} onClick={onClick}>
        <div className="sidebar-icon">
          <Icon className="icon-sm" />
        </div>
        <div className="sidebar-label">{children}</div>
      </div>
    );
  }

  return (
    <Link
      to={to}
      className={`sidebar-link ${
        pathname === to ? 'active' : ''
      }  ${className} ${heading ? 'heading-link-item' : ''}`}
      onClick={onClick}
    >
      <div className="sidebar-icon">
        <Icon className="icon-sm" />
      </div>
      <div className="sidebar-label">{children}</div>
    </Link>
  );
};

export default SideMenuItem;
