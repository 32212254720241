import { startsWith } from 'lodash';
import React, { useState } from 'react';

import './TextInput.scss';

const TextInput = React.forwardRef((props, ref) => {
  const {
    valueChange,
    defaultValue = '',
    large,
    onFocus,
    onBlur,
    label,
    placeholder,
    type,
    pattern,
  } = props;

  let classList = ['text-input'];
  classList.push(large ? 'text-input-lg' : 'text-input-md');

  const [inputValue, setInputValue] = useState(defaultValue);

  const currencyParse = (val) =>
    isNaN(val) || val === '' ? '' : `$${Number(val).toLocaleString()}`;

  switch (type) {
    case 'currency': {
      return (
        <label style={{ width: '100%', ...props.style }}>
          <span className="label-text">{label}</span>
          <input
            className={classList.join(' ')}
            type={'text'}
            pattern={pattern}
            onBlur={onBlur}
            value={currencyParse(inputValue)}
            onChange={({ target }) => {
              let numVal = parseFloat(`${target.value}`.replace(/(\$|,)/g, ''));
              if (isNaN(numVal)) {
                numVal = '';
              }
              setInputValue(numVal);
              valueChange(numVal);
            }}
            onFocus={onFocus}
            placeholder={placeholder || '$'}
            ref={ref}
            style={{ width: '100%' }}
          />
        </label>
      );
    }
    case 'link': {
      return (
        <label style={{ width: '100%', ...props.style }}>
          <span className="label-text">{label}</span>
          <input
            className={classList.join(' ')}
            type={'text'}
            pattern={pattern}
            onBlur={onBlur}
            defaultValue={defaultValue}
            onChange={({ target }) => {
              let changeValue = target.value;
              if (
                !startsWith(changeValue, 'https://') &&
                !startsWith(changeValue, 'http://')
              ) {
                changeValue = `https://${changeValue}`;
              }
              valueChange(changeValue);
            }}
            onFocus={onFocus}
            placeholder={placeholder || 'https://'}
            ref={ref}
            style={{ width: '100%' }}
          />
        </label>
      );
    }

    default:
      return (
        <>
          <label style={{ width: '100%', ...props.style }}>
            <span className="label-text">{label}</span>
            <input
              className={classList.join(' ')}
              defaultValue={defaultValue}
              type={type}
              pattern={pattern}
              onBlur={onBlur}
              onChange={(event) => valueChange(event.target.value)}
              onFocus={onFocus}
              placeholder={placeholder}
              ref={ref}
              style={{ width: '100%' }}
            />
          </label>
        </>
      );
  }
});

export default TextInput;
