import { Content, Header } from '../../components/ShellFrame';
import { Button, TextInput } from '../../frameworkUI/components';
import { Link } from 'react-router-dom';

const Login = () => {
  return (
    <>
      <Header sticky={true} />
      <Content scroll={true}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            maxWidth: '400px',
            margin: 'auto',
            justifyContent: 'center',
            height: '80%',
            alignItems: 'center',
          }}
        >
          <div style={{ width: '100%' }}>
            <div
              style={{
                marginTop: '2rem',
                marginBottom: '2rem',
                fontSize: '1.5rem',
                color: 'rgb(100,100,100)',
                textAlign: 'center',
              }}
            >
              Login
            </div>
            <div style={{ marginTop: '1rem' }}>
              Email
              <TextInput />
            </div>
            <div style={{ marginTop: '1rem' }}>
              Password
              <TextInput />
            </div>
            <div
              style={{
                width: '100%',
                marginTop: '1rem',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Button size="md" type="primary">
                Login
              </Button>

              <div>
                Not a member?{' '}
                <span style={{ color: 'rgb(100,100,100)' }}>Signup</span>
              </div>
            </div>
            <div style={{ marginTop: '3rem', color: 'rgb(80,80,80)' }}>
              <strong>Note:</strong> Tidal Board is currently in a closed beta.
              To create an account you must make a request in our{' '}
              <a
                href="https://discord.gg/kHudVDfj"
                style={{ color: '#0466C8', textDecoration: 'none' }}
              >
                Discord server
              </a>
            </div>
            <div
              style={{
                marginTop: '.5rem',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Link to="/demo-board">
                <Button size="md" type="secondary">
                  Try our demo board
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </Content>
    </>
  );
};

export default Login;
