import { useState } from 'react';
import {
  Admin,
  Bell,
  Briefcase,
  ChevronRight,
  ChevronLeft,
  ChevronDown,
  ChevronUp,
  Contact,
  HamburgerMenu,
  Kanban,
  Logo,
  Profile,
  Location,
  GrabIcon,
  OptionDotsIcon,
  USD
} from '../../frameworkUI/icons';
import './LogoDisplay.scss';

const LogoDisplay = () => {
  const iconList = [
    { Icon: Admin, label: 'Admin' },
    { Icon: Bell, label: 'Bell' },
    { Icon: Briefcase, label: 'Briefcase' },
    { Icon: ChevronRight, label: 'ChevronRight' },
    { Icon: ChevronLeft, label: 'ChevronLeft' },
    { Icon: ChevronDown, label: 'ChevronDown' },
    { Icon: ChevronUp, label: 'ChevronUp' },
    { Icon: Contact, label: 'Contact' },
    { Icon: HamburgerMenu, label: 'HamburgerMenu' },
    { Icon: Kanban, label: 'Kanban' },
    { Icon: Logo, label: 'Logo' },
    { Icon: Profile, label: 'Profile' },
    { Icon: Location, label: 'Location' },
    { Icon: GrabIcon, label: 'GrabIcon' },
    { Icon: OptionDotsIcon, label: 'OptionDotsIcon' },
    { Icon: USD, label: 'USD' },
  ];

  const [activeIcon, setActiveIcon] = useState(iconList[0]);

  const iconButtons = iconList.map(({ Icon, label }, index) => (
    <div
      className="icon-container"
      key={`icon-${index}`}
      onClick={() => setActiveIcon({ Icon, label })}
    >
      <Icon className="icon-md" />
      <div className="icon-label">{label}</div>
    </div>
  ));

  return (
    <div>
      <div className="display-container">
        <activeIcon.Icon className="icon-xs display-icon" />
        <activeIcon.Icon className="icon-sm display-icon" />
        <activeIcon.Icon className="icon-md display-icon" />
        <activeIcon.Icon className="icon-lg display-icon" />
        <div className="icon-label">{activeIcon.label}</div>
      </div>
      <div className="icon-list">{iconButtons}</div>
    </div>
  );
};

export default LogoDisplay;
