import { Header, Content } from '../../components/ShellFrame';

const Plans = () => {
  return (
    <>
      <Header sticky={true} />
      <Content scroll={true}>
        <div
          style={{
            display: 'flex',
            height: '80%',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{ fontSize: '1.9rem', color: 'rgb(100,100,100)' }}>
            Payment Plans - Coming soon
          </div>
        </div>
      </Content>
    </>
  );
};

export default Plans;
