import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Dropdown, TextInput } from '../../frameworkUI/components';
import {
  openCloumnModal,
  openJobModal,
} from '../../redux/actions/modalActions';
import {
  DropdownContent,
  OptionEvent,
} from '../../frameworkUI/components/Dropdown/Dropdown';
import { Bell, Profile } from '../../frameworkUI/icons';

import styles from './styles/OptionsBar.module.scss';
import { Link } from 'react-router-dom';

const OptionsBar = () => {
  const dispatch = useDispatch();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div id={styles.options_bar}>
      <div id={styles.main_options}>
        <Dropdown
          className="dropdown-container"
          onMouseLeave={() => setDropdownOpen(false)}
        >
          <Button size="sm" click={() => setDropdownOpen(true)}>
            Demo Board
          </Button>
          {dropdownOpen ? (
            <DropdownContent>
              {/* <OptionEvent onClick={() => {}}>My Boards</OptionEvent> */}
            </DropdownContent>
          ) : null}
        </Dropdown>
        <div id={styles.search_bar}>
          <TextInput border valueChange={() => {}} placeholder="Search..." />
        </div>
        <Dropdown
          className="dropdown-container"
          onMouseLeave={() => setDropdownOpen(false)}
        >
          <Button type="primary" size="sm" click={() => setDropdownOpen(true)}>
            + Add
          </Button>
          {dropdownOpen ? (
            <DropdownContent>
              <OptionEvent onClick={() => dispatch(openJobModal())}>
                Add Job
              </OptionEvent>
              <OptionEvent onClick={() => dispatch(openCloumnModal())}>
                Add Column
              </OptionEvent>
            </DropdownContent>
          ) : null}
        </Dropdown>
      </div>
      <div id={styles.profile_icons}>
        <Bell className="icon-md" style={{ opacity: '.6' }} />
        <Link to="/login" style={{ display: 'flex', alignItems: 'center' }}>
          <Profile className="icon-md" />
        </Link>
      </div>
    </div>
  );
};

export default OptionsBar;
