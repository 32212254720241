import { useEffect, useState, useRef } from 'react';
import axios from 'axios';

import { Button, TextInput } from '../../frameworkUI/components';

import './CompanyManagement.scss';

const DefaultCompany = {
  name: '',
  color: '',
};

const CompanyBox = (props) => {
  const { onClick, company, act } = props;
  return (
    <div
      className={`company-block ${
        company._id && company._id === act ? 'active' : ''
      }`}
      onClick={onClick}
    >
      {company.name}
    </div>
  );
};

const CompanyManagement = () => {
  const [companyList, setCompanyList] = useState([]);
  const [activeComp, setActiveComp] = useState(DefaultCompany);
  const [updatedComp, setUpdatedComp] = useState(DefaultCompany);

  const companyNameRef = useRef(null);
  const companyColorRef = useRef(null);

  useEffect(() => {
    companyNameRef.current.value = activeComp.name;
    companyColorRef.current.value = activeComp.color;
  }, [activeComp]);

  useEffect(() => {
    axios.get(`http://localhost:3001/company/all`).then((res) => {
      setCompanyList([...res.data]);
    });
  }, []);

  const updateCompany = () => {
    axios
      .put(`http://localhost:3001/company`, {
        headers: {},
        data: {
          company: updatedComp,
        },
      })
      .then(
        (response) => console.info(response),
        (error) => console.error(error)
      );
  };

  const createCompany = () => {
    axios
      .post(`http://localhost:3001/company`, {
        headers: {},
        data: {
          company: updatedComp,
        },
      })
      .then(
        (res) => {
          axios.get(`http://localhost:3001/company/all`).then((res) => {
            setCompanyList([...res.data]);
          });
        },
        (error) => console.error(error)
      );
  };

  return (
    <div>
      <div style={{ width: '100%', padding: '4px' }}>
        Admin Page{' '}
        <Button
          type="secondary"
          size="md"
          style={{ marginLeft: '1rem' }}
          click={() => {
            setActiveComp(DefaultCompany);
            setUpdatedComp(DefaultCompany);
            companyNameRef.current.value = activeComp.name;
            companyColorRef.current.value = activeComp.color;
          }}
        >
          Clear
        </Button>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <div
          style={{
            width: '300px',
            boxSizing: 'border-box',
            backgroundColor: 'white',
            height: '80vh',
            overflow: 'scroll',
          }}
        >
          <div>Search</div>
          {companyList.map((comp) => (
            <CompanyBox
              key={comp._id}
              company={comp}
              act={activeComp._id}
              onClick={() => {
                setActiveComp(comp);
                setUpdatedComp({ ...comp });
              }}
            />
          ))}
        </div>

        <div
          style={{
            backgroundColor: 'white',
            flexGrow: '1',
            paddingLeft: '1rem',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '40%' }}>
              <span style={{ marginBottom: '8px', fontWeight: '600' }}>
                Active
              </span>
              <br />
              Name: {activeComp.name}
              <br />
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                Color: {activeComp.color}
                <div
                  style={{
                    marginLeft: '8px',
                    width: '20px',
                    height: '20px',
                    backgroundColor: activeComp.color,
                  }}
                />
              </div>
            </div>
            <div style={{ width: '50%' }}>
              <span style={{ marginBottom: '8px', fontWeight: '600' }}>
                Updated
              </span>
              <br />
              Name: {updatedComp.name}
              <br />
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                Color: {updatedComp.color}
                <div
                  style={{
                    marginLeft: '8px',
                    width: '20px',
                    height: '20px',
                    backgroundColor: updatedComp.color,
                  }}
                />
              </div>
            </div>
          </div>
          <br />
          <br />
          <div style={{ width: '60%' }}>
            <TextInput
              large
              label="Name"
              style={{ width: '100%' }}
              ref={companyNameRef}
              valueChange={(val) =>
                setUpdatedComp({ ...updatedComp, name: val })
              }
            />
          </div>
          <br />
          <div
            style={{ width: '300px', display: 'flex', flexDirection: 'row' }}
          >
            <TextInput
              large
              label="Color"
              style={{ width: '100%' }}
              ref={companyColorRef}
              valueChange={(val) =>
                setUpdatedComp({ ...updatedComp, color: val })
              }
            />
            <div
              style={{
                width: '100px',
                height: '60px',
                marginLeft: '15px',
                backgroundColor: updatedComp.color,
              }}
            />
          </div>

          <Button
            type="primary"
            size="lg"
            style={{ margin: '2rem 0' }}
            click={() => {
              if (activeComp._id) {
                updateCompany();
              } else {
                createCompany();
              }
            }}
          >
            {activeComp._id ? 'Update' : 'Create'}
          </Button>
        </div>
      </div>
      <div>Status:</div>
    </div>
  );
};

export default CompanyManagement;
