import {
  JOB_MODAL_OPEN,
  JOB_MODAL_CLOSE,
  COLUMN_MODAL_OPEN,
  COLUMN_MODAL_CLOSE,
} from './actionTypes';

export const closeJobModal = () => (dispatch) => {
  console.info('Closing Job Modal');
  dispatch({ type: JOB_MODAL_CLOSE, payload: undefined });
};

export const openJobModal = (id = undefined) => (dispatch) => {
  console.log('Opening Job Modal');
  dispatch({ type: JOB_MODAL_OPEN, payload: { id } });
};

export const closeColumnModal = () => {
  console.info('Closing Column Modal');
  return { type: COLUMN_MODAL_CLOSE, payload: undefined };
};

export const openCloumnModal = () => {
  console.info('Opening Column Modal');
  return { type: COLUMN_MODAL_OPEN, payload: undefined };
};
