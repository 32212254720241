import { Bell, Briefcase, Location } from '../../frameworkUI/icons';
import styles from './styles/JobPanelDetails.module.scss';

const JobPanelDetails = (props) => {
  const {
    color = 'black',
    company,
    location,
    position,
    salary,
    link,
    datePosted,
    dateApplied,
  } = props.company;

  return (
    <div id={styles.job_details_body}>
      <div className={styles.job_title} style={{ color }}>
        {company}
      </div>
      {position && (
        <div className={styles.job_panel_attribute} style={{ color: 'black' }}>
          <Briefcase className={styles.icon_xs} />
          {position}
        </div>
      )}
      {location && (
        <div className={styles.job_panel_attribute}>
          <Location className={styles.icon_xs} />
          {location}
        </div>
      )}
      {salary && (
        <div className={styles.job_panel_attribute}>
          <Bell className={styles.icon_xs} />
          {Number(salary).toLocaleString()}
        </div>
      )}
      {link && (
        <div className={styles.job_panel_attribute}>
          <Bell className={styles.icon_xs} />
          <a href={link} alt={`${company} job posting`} target="_blank" rel="noopener">
            Link
          </a>
        </div>
      )}
      {datePosted && (
        <div className={styles.job_panel_attribute}>
          <Bell className={styles.icon_xs} />
          {datePosted}
        </div>
      )}
      {dateApplied && (
        <div className={styles.job_panel_attribute}>
          <Bell className={styles.icon_xs} />
          {dateApplied}
        </div>
      )}
    </div>
  );
};

export default JobPanelDetails;
