const Contacts = () => {
  return (
    <div
      style={{
        display: 'flex',
        height: '80%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{fontSize: "1.9rem", color: "rgb(100,100,100)"}}>Contacts Page - Coming soon</div>
    </div>
  );
};

export default Contacts;
