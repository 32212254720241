import { combineReducers } from 'redux';
import jobPanelReducer from './jobPanelReducer';
import kanbanReducer from './kanbanReducer';
import modalReducer from './modalReducer';

export default combineReducers({
  kanbanBoard: kanbanReducer,
  kanbanModal: modalReducer,
  jobPanel: jobPanelReducer,
});
