import { Header, Content } from '../../components/ShellFrame';

const Support = () => {
  return (
    <>
      <Header sticky={true} />
      <Content scroll={true}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '80%',
            width: '100%',
            alignItems: 'center',
            fontSize: '1.1rem',
          }}
        >
          <div
            style={{
              marginTop: '2rem',
              fontSize: '1.9rem',
              color: 'rgb(100,100,100)',
            }}
          >
            Support Page
          </div>
          <div style={{ width: '100%', maxWidth: '1000px', marginTop: '2rem' }}>
            Currently, the Tidal Board application is in early development.
            Features to create a profile, create a job board, among others may
            not be available.
          </div>
          <div style={{ width: '100%', maxWidth: '1000px', marginTop: '1rem' }}>
            If you have any questions, check out our Discord server:{' '}
            <a href="https://discord.gg/kHudVDfj">
              https://discord.gg/kHudVDfj
            </a>
          </div>
        </div>
      </Content>
    </>
  );
};

export default Support;
