import { useRef, useState } from 'react';
import TextInput from './TextInput';
import './Autocomplete.scss';

const Autocomplete = (props) => {
  const { fullList, defaultValue = '', label, onChange, large } = props;
  const max_items = 50;

  const [inputValue, setInputValue] = useState('');
  const [openOptions, setOpenOptions] = useState(false);

  const companyInputRef = useRef(null);

  let filteredList = fullList
    .filter(
      (item) =>
        item.value.substr(0, inputValue.length).toLowerCase() ===
        inputValue.toLowerCase()
    )
    .slice(0, max_items);

  const openList = () => {
    setOpenOptions(true);
  };
  const closeList = (e) => {
    // TODO: Try different approach that doesn't require timeout
    // Issue: Menu closes before click event is triggered after blur
    setTimeout(() => {
      setOpenOptions(false);
    }, 300);
  };

  const selectValue = (item) => {
    onChange(item);
    setInputValue(item.value);
    companyInputRef.current.value = item.value;
  };

  return (
    <div className="autocomplete">
      <TextInput
        defaultValue={defaultValue}
        large={large}
        style={{ width: '100%' }}
        valueChange={(value) => {
          onChange({ value });
          setInputValue(value);
        }}
        onFocus={openList}
        onBlur={closeList}
        ref={companyInputRef}
        label={label}
      />
      {openOptions ? (
        <div className="autocomplete-items">
          {filteredList.map((item) => (
            <div
              key={item._id}
              onClick={() => {
                selectValue(item);
                setOpenOptions(false);
              }}
            >
              {item.value}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default Autocomplete;
