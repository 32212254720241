import { useState } from 'react';
import { Link } from 'react-router-dom';
import { SideMenuItem } from '.';

import { ChevronLeft, ChevronRight } from '../../icons';

import './SideMenu.scss';

const SideMenu = (props) => {
  const { children } = props;
  const [open, setOpen] = useState(true);

  return (
    <div id="side-menu" className={open ? 'open' : 'closed'}>
      {children}
      {/* <div
        id=""
        onClick=
      > */}
      {open ? (
        <SideMenuItem
          Icon={ChevronLeft}
          className="cls-chev toggle-chevron"
          onClick={() => {
            setOpen((val) => !val);
          }}
        >
          Close
        </SideMenuItem>
      ) : (
        <SideMenuItem
          Icon={ChevronRight}
          className="cls-chev toggle-chevron"
          onClick={() => {
            setOpen((val) => !val);
          }}
        />
      )}
    </div>
  );
};

export default SideMenu;
