import { JOB_PANEL_CLOSE, JOB_PANEL_OPEN } from '../actions/actionTypes';

const initialState = {
  id: undefined,
  open: false,
  options: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case JOB_PANEL_OPEN: {
      const { id } = action.payload;
      return {
        ...state,
        id,
        open: true,
      };
    }
    case JOB_PANEL_CLOSE: {
      return initialState;
    }
    default:
      return state;
  }
}
