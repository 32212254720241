import { Button } from '../../frameworkUI/components';
import './Modal.scss';

export const ModalHeading = (props) => {
  return <div className="modal-heading">{props.children}</div>;
};

export const ModalSpacing = () => {
  return <div style={{ flexGrow: '1' }}></div>;
};

export const ModalButtonBar = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
      }}
    >
      <Button type={'secondary'} size={'lg'} click={props.secondary.action}>
        {props.secondary.text}
      </Button>
      <Button
        type={'primary'}
        size={'lg'}
        style={{ marginLeft: '2rem' }}
        click={props.primary.action}
      >
        {props.primary.text}
      </Button>
    </div>
  );
};

const Modal = (props) => {
  const { size, onCancelClose } = props;
  return (
    <>
      <div className="modal-background-filter" onClick={onCancelClose}></div>
      <div className={`modal-shell modal-${size}`}>
        <div className="modal-content">{props.children}</div>
      </div>
    </>
  );
};

export default Modal;
