import { Provider } from 'react-redux';
import store from './redux/store';
import { Switch, Route } from 'react-router-dom';

import { Admin, Dashboard, Login, Main, Plans, Support } from './Views';

import './App.scss';

function App() {
  return (
    <Provider store={store}>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Switch>
          <Route exact path="/">
            <Main />
          </Route>
          <Route path="/dashboard">
            <Login />
            {/* <Dashboard /> */}
          </Route>
          <Route path="/demo-board">
            <Dashboard />
          </Route>
          <Route path="/support">
            <Support />
          </Route>
          <Route path="/plans">
            <Plans />
          </Route>
          <Route path="/admin">
            <Admin />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
        </Switch>
      </div>
    </Provider>
  );
}

export default App;
