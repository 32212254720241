import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Bell, Logo, HamburgerMenu, Profile } from '../../frameworkUI/icons';

import './Header.scss';

const HeaderLogo = () => {
  return (
    <Link to="/" id="header-logo">
      <Logo className="icon-md" />
      {/* <img src={Logo}  /> */}
      Tidal Board
    </Link>
  );
};

const HeaderLinks = (props) => {
  const { location } = props;
  return (
    <div id="header-links">
      <Link
        to="/dashboard"
        className={
          location.indexOf('dashboard') === 1 ? 'active-header-link' : null
        }
      >
        Dashboard
      </Link>
      <Link
        to="/support"
        className={
          location.indexOf('support') === 1 ? 'active-header-link' : null
        }
      >
        Support
      </Link>
      <Link
        to="/plans"
        className={
          location.indexOf('plans') === 1 ? 'active-header-link' : null
        }
      >
        Plans
      </Link>
    </div>
  );
};

const HeaderIcons = (props) => {
  const { burger } = props;
  return (
    <div id="header-icons">
      <Bell className="icon-md" style={{ opacity: '.6' }} />
      <Link to="/login" style={{ display: 'flex', alignItems: 'center' }}>
        <Profile className="icon-md" />
      </Link>
      {burger ? <HamburgerMenu className="icon-md" /> : null}
    </div>
  );
};

const Header = (props) => {
  const { sticky } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
  const location = useLocation().pathname;

  return (
    <header className={sticky ? 'sticky-header' : 'non-sticky-header'}>
      <HeaderLogo />
      {windowWidth >= 700 ? <HeaderLinks location={location} /> : null}
      <HeaderIcons burger={windowWidth < 700} />
    </header>
  );
};

export default Header;
